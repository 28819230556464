import React from 'react'
import { Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import { footerTextLine1, footerTextLine2, footerCopyright, gamecraftAgreement, privacyPolicy } from '../../cms'

import fbLogo from '../../assets/images/fb_logo.png'
import youtubeLogo from '../../assets/images/youtube_logo.png'
import gcLogo from '../../assets/images/gc_logo.png'
import digitoLogo from '../../assets/images/digito_logo.png'
import footerBg from '../../assets/images/footer.png'

const Container = styled.div`
    background-image: url(${footerBg});
    background-size: contain;
    z-index: 100;
    position: relative;
`;

const Wrapper = styled(Grid)`
    color: white;
    padding-bottom: 20px !important;

    p {
        :first-child {
            margin-top: 15px;
        }

        margin: 2px 0;

        a {
            color: white;
        }
    }

    @media all and (min-width:0px) and (max-width: 768px) {
       font-size: 10px;

       p {
            :first-child {
                margin-top: 0px;
            }
        }
    }
`

const Footer = () => {
    return (
        <Container>
            <Wrapper container>
                <Grid.Row>
                    <Grid.Column mobile={2} computer={1}>
                        <a href='https://www.facebook.com/dummypueak' target='_blank' rel='noreferrer'><Image src={fbLogo} size='mini' /></a>
                    </Grid.Column>
                    <Grid.Column style={{marginTop: 5}} mobile={2} computer={1}>
                        <a href='https://www.youtube.com/channel/UClvfvnc1u0VJiu0Sf4dVr8g' target='_blank' rel='noreferrer'><Image src={youtubeLogo} size='mini' /></a>
                    </Grid.Column>
                    <Grid.Column mobile={12} computer={16}>
                        <p>{footerTextLine1}</p>
                        <p>{footerTextLine2}</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Image src={gcLogo} />
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Image src={digitoLogo} />
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <div style={{borderLeft: 'solid 1px white', paddingLeft: 20}}>
                            <p>
                                {footerCopyright}
                            </p>
                            <p>
                            <a href='https://www.playgamecraft.com/privacypolicy-th' target='_blank' rel='noreferrer'>{privacyPolicy}</a>
                            &nbsp;|&nbsp;
                            <a href='https://www.playgamecraft.com/termsofservice-th' target='_blank' rel='noreferrer'>{gamecraftAgreement}</a>
                            </p>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Wrapper>ef
        </Container>
    )
}

export default Footer
