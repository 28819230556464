import React, { Dispatch, SetStateAction, useState } from 'react'
import { Image, Button, Modal, Input, Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import axios from 'axios';
import querystring from 'querystring';
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';

import fbLogin from '../../assets/images/fb_login.png'
import loginHeader from '../../assets/images/login_header.png'
import closeButton from '../../assets/images/close_button.png'
import progressCharacter from '../../assets/images/progress_character.png'
import mistakeCharacter from '../../assets/images/mistake_character.png'
import {
    loginMessage, or, loginUID, announmentLine1, announmentLine2, announmentLine3, announmentLine4, announmentLine5, announmentLine6,
    loginUidHeader, inputUidPlaceholder, inputGoldPlaceholder, inputLoginChannelPlaceholder, loginUidButton,
    loginInProgressHeader, loginInProgressSubHeader, loginCompleteHeader, loginCompleteSubHeader,
    loginCompleteSubmitButton, loginFailureHeader, loginFailureSubHeader, loginFailureButton,
} from '../../cms';
import { LoginInfo } from '../App/App';

const UIDButton = styled(Button)`
  width: 300px;
  height: 64px;
  background: #881e16 !important;
  color: white !important;
  font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
    font-size: 18px !important;
`

const LoginUidButton = styled(Button)`
    width: 150px;
    height: 48px;
    background: #e59640 !important;
    color: white !important;
    font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif !important;
    font-size: 18px !important;
    padding: 0;
    border-radius: .8rem !important;
`

const Container = styled.div`
    h4 {
        margin: 10px 0 20px 0;
    }

    h5 {
        text-align: center;
        width: 300px;
        margin: 12px 0;
    }

    p {
        margin: 0;
    }

    @media all and (min-width:0px) and (max-width: 768px) {
       text-align: center;

       img {
           margin: auto;
       }

       h5 {
            text-align: center;
            width: 100%;
            margin: 12px 0;
        }
    }
`;

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    img {
        width: 200px !important;
    }
`;

const CloseIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const CloseIcon = styled(Image)`
    height: 40px !important;
    cursor: pointer;
    position: relative;
    bottom: 10px;
    left: 10px;
`;

const ModalContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h4 {
        margin: 20px 0;
        font-weight: bold;
        font-size: 18px !important;
    }

    div.ui.selection.dropdown {
        text-align: center !important;
    }

    input, div.ui.selection.dropdown {
        width: 380px !important;
        margin: 4px 0 !important;

        ::placeholder, .item, .text {
            text-align: center !important;
            color: #A7A9AC !important;
            font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif !important;
        }
    }
`;

const TermContainer = styled.div`
    @media all and (min-width:0px) and (max-width: 768px) {
       text-align: left;
       width: 80%;
       left: 10%;
       height: 300px;
       position: relative;
       margin: 20px 0;
    }
`;

const friendOptions = [
    {
      key: 'channel_facebook',
      text: 'บัญชี Facebook',
      value: 1,
    },
    {
      key: 'channel_line',
      text: 'บัญชี Line',
      value: 6,
    },
    {
      key: 'channel_gamecenter',
      text: 'บัญชี GameCenter',
      value: 2,
    },
    {
      key: 'channel_googleplay',
      text: 'บัญชี GooglePlayGames',
      value: 3,
    },
    {
      key: 'channel_apple',
      text: 'บัญชี Apple',
      value: 7,
    },
  ]

interface Props {
    setIsLogin: Dispatch<SetStateAction<boolean>>;
    setLoginData: Dispatch<SetStateAction<LoginInfo>>;
}

const Login = (props: Props) => {
    const [open, setLoginOpen] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);
    const [isLoginComplete, setIsLoginComplete] = useState(false);
    const [isLoginFailure, setIsLoginFailure] = useState(false);

    // UID info
    const [uid, setUid] = useState(0);
    const [platform, setPlatform] = useState(0);
    const [gold, setGold] = useState(0);

    const loginFacebook = async (response: ReactFacebookLoginInfo) => {
        setIsInProgress(true);
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const res = await axios.post('https://dummy-ws.digitopolis.studio/webpayment/VerifyFacebookAccount', querystring.stringify({
            accountkey: response.userID,
        }), config);

        const { data } = res;

        setIsInProgress(false);
        if (data.Status === 'OK') {
            setIsLoginComplete(true);
            props.setLoginData(data);
        } else {
            setIsLoginComplete(false);
        }
    }

    const failureResponseFacebook = () => {
        setIsInProgress(false);
        setIsLoginComplete(false);
    }

    const loginUid = async () => {
        setIsInProgress(true);
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        const res = await axios.post('https://dummy-ws.digitopolis.studio/webpayment/VerifyUserUID', querystring.stringify({
            uid,
            platform,
            gold
        }), config);

        const { data } = res;

        setIsInProgress(false);
        if (data.Status === 'OK') {
            setIsLoginComplete(true);
            props.setLoginData(data);
        } else {
            setIsLoginFailure(true);
            setIsLoginComplete(false);
        }
    }

    return (
        <Container>
            <h4>{loginMessage}</h4>
            <FacebookLogin
                appId="704411143325931"
                fields="name"
                callback={loginFacebook}
                icon={<Image src={fbLogin} size='medium' style={{cursor: 'pointer'}} />}
                onFailure={failureResponseFacebook}
                textButton=""
                buttonStyle={{
                    border: 'none',
                    background: 'transparent',
                    padding: 0,
                }}
            />
            <h5>{or}</h5>
            <UIDButton onClick={() => setLoginOpen(true)}>{loginUID}</UIDButton>
            <Modal
                closeIcon={<CloseIconContainer><CloseIcon src={closeButton} onClick={() => setLoginOpen(false)} /></CloseIconContainer>}
                open={open}
            >
                <Modal.Content>
                    <Modal.Description>
                        <ImageContainer>
                            <Image src={loginHeader} size="medium" />
                        </ImageContainer>
                        <ModalContainer>
                            <h4>{loginUidHeader}</h4>
                            <p>
                                <Input type='number' placeholder={inputUidPlaceholder} onChange={(_, data) => {setUid(parseInt(data.value));}} />
                            </p>
                            <p>
                                <Input type='number' placeholder={inputGoldPlaceholder} onChange={(_, data) => {setGold(parseInt(data.value));}} />
                            </p>
                            <p>
                                <Dropdown placeholder={inputLoginChannelPlaceholder} selection options={friendOptions} onChange={(_, data) => {setPlatform(parseInt(data?.value?.toString() || '0', 10) || 0)}} />
                            </p>
                            <p>
                                <LoginUidButton onClick={() => loginUid()}>{loginUidButton}</LoginUidButton>
                            </p>
                        </ModalContainer>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
            <br/>
            <br/>
            <TermContainer>
                <p>{announmentLine1}</p>
                <p>{announmentLine2}</p>
                <p>{announmentLine3}</p>
                <p>{announmentLine4}</p>
                <p>{announmentLine5}</p>
                <p>{announmentLine6}</p>
            </TermContainer>
            {/* // LOGIN IN PROGRESS */}
            <Modal open={isInProgress}>
                <Modal.Content>
                    <Modal.Description>
                        <ImageContainer>
                            <Image src={progressCharacter} size="medium" />
                        </ImageContainer>
                        <ModalContainer>
                            <h4>{loginInProgressHeader}</h4>
                            <p>{loginInProgressSubHeader}</p>
                        </ModalContainer>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
            <Modal open={isLoginComplete}>
                <Modal.Content>
                    <Modal.Description>
                        <ImageContainer>
                            <Image src={progressCharacter} size="medium" />
                        </ImageContainer>
                        <ModalContainer>
                            <h4>{loginCompleteHeader}</h4>
                            <p>{loginCompleteSubHeader}</p>
                            <p>
                                <LoginUidButton onClick={() => props.setIsLogin(true)}>{loginCompleteSubmitButton}</LoginUidButton>
                            </p>
                        </ModalContainer>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
            <Modal
                closeIcon={<CloseIconContainer><CloseIcon src={closeButton} onClick={() => setIsLoginFailure(false)} /></CloseIconContainer>}
                open={isLoginFailure}
            >
                <Modal.Content>
                    <Modal.Description>
                        <ImageContainer>
                            <Image src={mistakeCharacter} size="medium" />
                        </ImageContainer>
                        <ModalContainer>
                            <h4>{loginFailureHeader}</h4>
                            <p>{loginFailureSubHeader}</p>
                            <p>
                                <LoginUidButton onClick={() => setIsLoginFailure(false)}>{loginFailureButton}</LoginUidButton>
                            </p>
                        </ModalContainer>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </Container>
    );
}

export default Login
