import React, { useState } from 'react';
import { Grid, Image } from 'semantic-ui-react';
import styled from 'styled-components';

import Footer from '../Footer'
import Login from '../Login'
import Redeem from '../Redeem'
import dummyLogo from '../../assets/images/dummy_logo.png'
import headerLogo from '../../assets/images/header.png'
import characterPlay from '../../assets/images/character_play.png'
import logoutButton from '../../assets/images/logout_button.png'
import bg from '../../assets/images/bg.png'
import bgMobile from '../../assets/images/bg_mobile.png'

const GridWrapper = styled(Grid)`
  padding-top: 3vh !important;
  min-height: calc(100vh - 200px);

  img {
    height: 150px;
  }

  @media all and (min-width:0px) and (max-width: 768px) {
    img {
      height: 100px;
    }
  }
`

const ImageContainer = styled.div`
  img {
    margin-top: 100px;
  }


  @media all and (min-width:0px) and (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0;

      img {
        margin: 0;
      }
  }
`;

const Header = styled.div`
  height: 40px;
`;

const Container = styled.div`
  background:  #f7ce4f;
  background-image: url(${bg});
  background-size: cover;


  @media all and (min-width:0px) and (max-width: 768px) {
    background-image: url(${bgMobile});
    background-size: cover;
  }
`;

const LogoContainer = styled(Grid.Column)`
  align-items: center !important;
  display: flex !important;
  flex-direction: column !important;
  justify-items: center !important;
  justify-content: space-between !important;
  padding: 4rem 0 !important;

  @media all and (min-width:0px) and (max-width: 768px) {
    padding: 0 !important;
    height: 100px !important;
  }
`;

const CharacterImage = styled(Image)`
  position: relative;
  right: 10rem;
  top: 10rem;
  width: 1200px !important;
  max-width: 1200px !important;

  @media all and (min-width:0px) and (max-width: 768px) {
    width: 100% !important;
    top: 37rem;
    left: 0rem;
    position: relative;
  }
`;

const LogoImage = styled(Image)`
  @media all and (min-width:0px) and (max-width: 768px) {
    padding: 0 !important;
    width: 100px !important;
  }
`;

const LogoutButton = styled(Image)`
  width: 100px !important;
  height: auto !important;
  position: relative;
  top: 50px;
  cursor: pointer;

  @media all and (min-width:0px) and (max-width: 768px) {
    top: -70px;
  }
`;

const ContentContainer = styled(Grid.Column)`
  bottom: 20px;
`;

export interface LoginInfo {
  Status: string;
  accountkey: string;
  name: string;
  paymentkey: string;
  photourl: string;
  platform: number;
  uid: string;
  chip: string;
  gold: string;
  ticket: string;
}

const App = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [loginData, setLoginData] = useState({} as LoginInfo)

  const renderContent = () => {
    if (isLogin) {
      return (<Redeem loginData={loginData} />)
    }
    return (<Login setIsLogin={setIsLogin} setLoginData={setLoginData} />)
  }

  return (
    <>
      <Container>
        <Header />
        <GridWrapper container>
          <Grid.Row>
            <LogoContainer tablet={7} mobile={16} computer={7}>
              <LogoImage src={dummyLogo} size='medium' />
              <CharacterImage src={characterPlay} size='huge' />
            </LogoContainer>
            <ContentContainer tablet={9} mobile={16} computer={9}>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                { isLogin ? (<LogoutButton src={logoutButton} onClick={() => window.location.reload()} />) : null }
              </div>
              <ImageContainer><Image src={headerLogo} size='medium' /></ImageContainer>
              {renderContent()}
            </ContentContainer>
          </Grid.Row>
        </GridWrapper>
      </Container>
      <Footer />
    </>
  );
}

export default App;
