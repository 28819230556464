export const footerTextLine1 = 'ดาวน์โหลดความสุขได้แล้ววันนี้เวอร์ชั่น android และ iOS'
export const footerTextLine2 = 'พร้อมพบกิจกรรมสนุกๆมากมายในเกม ไปโหลดกันเลย'
export const footerCopyright = '© 2021 GAMECRAFT COMPANY LIMITED. All rights reservered.'
export const privacyPolicy = 'Privacy Policy'
export const gamecraftAgreement = 'GAMECRAFT agreement'

// LOGIN
export const loginMessage = 'กรุณาเลือกช่องทางการล๊อกอิน'
export const or = 'หรือ'
export const loginUID = 'เข้าสู่ระบบด้วยบัญชี UID เกม'
export const announmentLine1 = 'ประกาศ'
export const announmentLine2 = '1. รหัสไอเทม มีทั้งหมด 10 หลัก ประกอบด้วยตัวอักษรและตัวเลข'
export const announmentLine3 = '2. กรุณาล๊อกอินและตรวจสอบความถูกต้องของบัญชีของท่าน'
export const announmentLine4 = '3. หากรหัสหมดอายุแล้ว จะไม่สามารถใช้งานได้'
export const announmentLine5 = '4. หากท่านพบปัญหาใดๆ กรุณาติดต่อฝ่ายบริการลูกค้า'
export const announmentLine6 = '5. หากใช้ Guest ID จะไม่สามารถแลกไอเท็มได้ กรุณาติดต่อฝ่ายบริการลูกค้าเพื่อผูกบัญชีก่อน'

// REDEEM
export const userInfoHeader = 'บัญชีผู้เล่น'
export const userInfoSubHeader = 'กรุณาตรวจสอบความถูกต้องของบัญชี'
export const chip = 'ชิป:'
export const gold = 'โกลด์'
export const ticket = 'ทิกเก็ต'
export const id = 'ID'
export const loginChannel = 'ช่องทาง login'
export const exhangeHeader = 'แลกไอเทมโค้ด'
export const exhangeSubHeader = 'กรุณากรอกไอเทมโค้ด'
export const exhangePlaceholder = 'กรอกรหัสไอเทมที่นี้'
export const exhangeButton = 'ยืนยัน'

// LOGIN MODAL
export const loginUidHeader = 'กรอกข้อมูลบัญชีเกมที่ต้องการเติมเงินเพื่อเข้าสู่ระบบ'
export const inputUidPlaceholder = 'กรอกเลข UID ของท่าน'
export const inputGoldPlaceholder = 'กรอกจำนวนโกลด์ในเกมที่มี'
export const inputLoginChannelPlaceholder = 'เลือกช่องทางเข้าสู่ระบบ'
export const loginUidButton = 'ยืนยัน'

// LOGIN IN PROGRESS
export const loginInProgressHeader = 'โปรดรอสักครู่'
export const loginInProgressSubHeader = 'ระบบกำลังตรวจสอบข้อมูลของท่าน'

export const loginCompleteHeader = 'เข้าสู่ระบบสำเร็จแล้ว'
export const loginCompleteSubHeader = 'กดปุ่ม “ตกลง” เพื่อเข้าสู่หน้าหลัก'
export const loginCompleteSubmitButton = 'ตกลง'

export const loginFailureHeader = 'ท่านกรอกข้อมูลไม่ถูกต้อง'
export const loginFailureSubHeader = 'โปรดตรวจสอบข้อมูลการเข้าสู่ระบบอีกครั้ง'
export const loginFailureButton = 'ย้อนกลับ'

// REDEEM
export const redeemCompleteHeader = 'นายท่านแลกไอเทมสำเร็จแล้ว'
export const redeemCompleteSubHeader = 'กรุณาตรวจสอบกล่องข้อความในเกม'
export const redeemCompleteSubmitButton = 'ตกลง'

export const redeemFailureHeader = 'แลกไอเทมไม่สำเร็จ'
export const redeemFailureSubHeader = 'กรุณาตรวจสอบรหัสไอเทมโค้ดใหม่อีกครั้ง'
export const redeemFailureSubHeader2 = 'รหัสไอเทมของท่านหมดอายุแล้ว'
export const redeemFailureButton = 'ตกลง'
