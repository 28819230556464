import React, { useState } from 'react'
import { Grid, Input, Image, Modal, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import axios from 'axios';
import querystring from 'querystring';

import frameBg from '../../assets/images/frame.png'
import confirmButton from '../../assets/images/confirm_button.png'
import progressCharacter from '../../assets/images/progress_character.png'
import {
    userInfoHeader, userInfoSubHeader, chip,
    gold, ticket, id, loginChannel, exhangeHeader,
    exhangeSubHeader, exhangePlaceholder, loginInProgressHeader, loginInProgressSubHeader,
    redeemCompleteHeader, redeemCompleteSubHeader, redeemCompleteSubmitButton,
    redeemFailureHeader, redeemFailureSubHeader, redeemFailureButton
} from '../../cms'
import { LoginInfo } from '../App/App';

const RedeemContainer = styled.div`
    background-image: url(${frameBg});
    background-size: contain;
    background-repeat: no-repeat;
    height: 300px;
    padding: 40px;
    display: block;
    color: white;
    font-size: 18px !important;

    @media all and (min-width:0px) and (max-width: 1024px) {
        padding: 30px;
        font-size: 14px !important;
        background-size: 100% 260px;

        p {
            font-size: 14px;
        }
    }
`

const TableDescription = styled.td`
    color: #F8F41D;
    text-align: right;
`

const Header = styled.span`
  color: #E6E6E6;
  font-size: 18px !important;
  background: #6fd4c1;
  display: block;
  width: 200px;
  border-radius: .4rem;
  margin-bottom: 10px;
  height: 28px;

  span {
      position: relative;
      top: 5px;
      left: 20px;
      text-shadow: -1px 0 #2b653b, 0 1px #2b653b, 1px 0 #2b653b, 0 -1px #2b653b;

        @media all and (min-width:0px) and (max-width: 1024px) {
            font-size: 14 !important;
        }
  }

  @media all and (min-width:0px) and (max-width: 1024px) {
        font-size: 14 !important;
    }
`

const RedHeader = styled.span`
    background: #cd5b41;
    text-align: center;
    padding: 4px 20px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    border-radius: .4rem;
    filter: drop-shadow(0 2px 0 black);
    width: 120px;
    height: 30px;
    display: block;
    font-size: 18px;

    @media all and (min-width:480px) and (max-width: 1024px) {
        width: 100%;
        font-size: 11px;
    }

    @media all and (min-width:0px) and (max-width: 480px) {
        font-size: 11px;
        width: 100%;
    }
`;

const RedeemTable = styled.table`
    background: #39797d;
    border-radius: .4rem;
    padding: 10px;
    position: relative;
    width: 280px;

    @media all and (min-width:0px) and (max-width: 1024px) {
        width: 100%;
        font-size: 14px;
        line-height: 0.9;
    }
`;

const RedeemInput = styled(Input)`
    width: 85%;

    @media all and (min-width:0px) and (max-width: 1024px) {
        width: 100%;
        text-align: center;
    }

    input{
        background: #39797d !important;
        font-size: 32px;
            color: #f6f255 !important;
        font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
        padding: .8rem !important;

        @media all and (min-width:0px) and (max-width: 1024px) {
            font-size: 24px;
        }

        ::placeholder {
            color: #f6f255 !important;
            text-align: center;
        }

        :focus {
            border: none !important;
        }
    }
`;

const ConfrimButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    img {
        height: 50px !important;
        width: auto !important;
        right: 30px;

        @media all and (min-width:0px) and (max-width: 1024px) {
            right: 0;
        }
    }
`;

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    img {
        width: 200px !important;
    }
`;

const ModalContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h4 {
        margin: 20px 0;
        font-weight: bold;
        font-size: 18px !important;
    }

    div.ui.selection.dropdown {
        text-align: center !important;
    }

    input, div.ui.selection.dropdown {
        width: 380px !important;
        margin: 4px 0 !important;

        ::placeholder, .item, .text {
            text-align: center !important;
            color: #A7A9AC !important;
            font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif !important;
        }
    }
`;

const LoginUidButton = styled(Button)`
    width: 150px;
    height: 48px;
    background: #e59640 !important;
    color: white !important;
    font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif !important;
    font-size: 18px !important;
    padding: 0;
    border-radius: .8rem !important;
`

interface Props {
    loginData: LoginInfo;
}

const Redeem = (props: Props) => {
    const renderLoginChannel = () => {
        switch(props.loginData.platform) {
            case 1:
                return 'Facebook';
            case 2:
                return 'Game Center';
            case 3:
                return 'Google Play';
            case 4:
                return 'Touring IOS';
            case 5:
                return 'Touring Android';
            case 6:
                return 'Line';
            case 7:
                return 'Apple ID';
            default:
                return '';
          }
    }
    const [isInProgress, setIsInProgress] = useState(false);
    const [isRedeemComplete, setIsRedeemComplete] = useState(false);
    const [isRedeemFailure, setIsRedeemFailure] = useState(false);
    const [redeemFailureMsg, setRedeemFailureMsg] = useState('');
    const [redeemCode, setRedeemCode] = useState('');

    const redeem = async () => {
        setIsInProgress(true);
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

            axios.post('https://dummy-ws.digitopolis.studio/redeem/RedeemCode', querystring.stringify({
                uid: props.loginData.uid,
                redeem_code: redeemCode,
            }), config).then((res)=>{
                
                setIsInProgress(false);
                if (res.status === 200 && res.data.Status === "Success") {
                    setIsRedeemComplete(true);
                } else {
                    
                    setIsInProgress(false);
                    setIsRedeemFailure(true);
                    setRedeemFailureMsg(res.data.Message);
                }
            }).catch((error)=>{
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the 
                    // browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                    console.log( error.request._response );
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    console.log('Error', error.Message);
                }
                setIsInProgress(false);
                setIsRedeemFailure(true);                
            });
            
    }

    return (
        <>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <RedeemContainer>
                            <Header><span>{userInfoHeader}</span></Header>
                            <p>{userInfoSubHeader}</p>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column mobile={6} tablet={5} computer={4} textAlign={'center'}>
                                        <RedHeader>{props.loginData.name}</RedHeader>
                                    </Grid.Column>
                                    <Grid.Column mobile={10} tablet={11} computer={12}>
                                        <RedeemTable>
                                            <tr>
                                                <td>
                                                    {chip}:
                                                </td>
                                                <TableDescription>
                                                    {props.loginData.chip}
                                                </TableDescription>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {gold}:
                                                </td>
                                                <TableDescription>
                                                    {props.loginData.gold}
                                                </TableDescription>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {ticket}:
                                                </td>
                                                <TableDescription>
                                                    {props.loginData.ticket}
                                                </TableDescription>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {id}:
                                                </td>
                                                <TableDescription>
                                                    {props.loginData.uid}
                                                </TableDescription>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {loginChannel}:
                                                </td>
                                                <TableDescription>
                                                    {renderLoginChannel()}
                                                </TableDescription>
                                            </tr>
                                        </RedeemTable>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </RedeemContainer>
                        <RedeemContainer>
                            <Header><span>{exhangeHeader}</span></Header>
                            <p>{exhangeSubHeader}</p>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column mobile={16}>
                                        <RedeemInput placeholder={exhangePlaceholder} onChange={(_: any, data: any) => {setRedeemCode(data.value);}} />
                                        <ConfrimButtonContainer>
                                            <Image src={confirmButton} onClick={() => {redeem()}} />
                                        </ConfrimButtonContainer>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </RedeemContainer>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {/* // LOGIN IN PROGRESS */}
            <Modal open={isInProgress}>
                <Modal.Content>
                    <Modal.Description>
                        <ImageContainer>
                            <Image src={progressCharacter} size="medium" />
                        </ImageContainer>
                        <ModalContainer>
                            <h4>{loginInProgressHeader}</h4>
                            <p>{loginInProgressSubHeader}</p>
                        </ModalContainer>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
            <Modal open={isRedeemComplete}>
                <Modal.Content>
                    <Modal.Description>
                        <ImageContainer>
                            <Image src={progressCharacter} size="medium" />
                        </ImageContainer>
                        <ModalContainer>
                            <h4>{redeemCompleteHeader}</h4>
                            <p>{redeemCompleteSubHeader}</p>
                            <p>
                                <LoginUidButton onClick={() => setIsRedeemComplete(false)}>{redeemCompleteSubmitButton}</LoginUidButton>
                            </p>
                        </ModalContainer>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
            <Modal open={isRedeemFailure}>
                <Modal.Content>
                    <Modal.Description>
                        <ImageContainer>
                            <Image src={progressCharacter} size="medium" />
                        </ImageContainer>
                        <ModalContainer>
                            <h4>{redeemFailureHeader}</h4>
                            <p>{redeemFailureMsg ?? redeemFailureSubHeader}</p>
                            <p>
                                <LoginUidButton onClick={() => setIsRedeemFailure(false)}>{redeemFailureButton}</LoginUidButton>
                            </p>
                        </ModalContainer>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    )
}

export default Redeem